@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700&display=swap);

body {
  font-family: 'IBM Plex Sans', sans-serif !important;
}

.min-height-150 {
  min-height: 150px;
}

.width-30 {
  width: 30% !important;
}

.switch {
  width: 100px;
}

.login-screen {
  position:fixed;
  padding:0;
  margin:0;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
}

.progress-data {
  width: 100%;
}

.center-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.align-right {
  text-align: right; 
}

.align-center {
  text-align: center;
}

.first-col-db {
  height: 285px;
}

.custom-green {
  background-color: #A7D18B !important;
}

.custom-red {
  background-color: #E55763 !important;
}

.custom-orange {
  background-color: #F2B589 !important;
}

.border-left-success {
    border-left: .25rem solid #A7D18B !important;
}

th.active .arrow {
  opacity: 1;
}

.text-truncate-wrap {
  white-space: normal;
}

.infinite-scroll table tfoot td {
  font-style: italic;
  font-size: smaller;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #42b983;
}

.arrow.desc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #42b983;
}

.cursorPointer {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

#donut-chart .apexcharts-legend-series {
  margin-top: 5px !important;
}


.sign-in {
  border: solid 2px #171E3F;
  border-radius: 47px;
}

.sign-in input {
  border: solid 2px #171E3F;
  border-radius: 10px !important;
}

.btn-sign-in-dark, .btn-change-pass {
  background-color: #171E3F !important;
  color: white !important;
  border-radius: 10px !important;
}

.sign-in-text {
  color: #171E3F;
  font-size: 40px !important;
  font-weight: bold !important;
}

.sign-in-bottom-text, .sign-in-bottom-text:hover {
  text-decoration: none;
  color: #171E3F;
  font-weight: bold;
}

.sign-in-bottom-text:hover {
  font-weight: bold;
}

.btn-change-pass:hover, .btn-sign-in-dark:hover  {
  background-color: #171E3F;
  color: white;
  font-weight: bold;
  border-radius: 10px !important;
}

.custom-container {
  padding: 30px;
}

td.actions {
  min-width: 160px;
  vertical-align: middle !important;
}

.ck-editor {
  position: relative;
  margin-bottom: 18px;
  margin-top: 18px;
}

.ck-editor__editable {
  min-height: 200px;
}

.balance-container blockquote, .balance-container pre {
  border: none;
  border-left: 2px solid #b7b9cc;
  padding-left: 1em;
}

@media print {
  #wrapper #content-wrapper {
    background-color: white !important;
  }

  .principle-table thead th {
    text-align: center;
    text-transform: uppercase;
  }

  .principle-table .principle-header-openAndVoluntary th {
    background-color: rgb(125, 59, 165, 0.2) !important;
  }
  .principle-table .principle-header-democraticControl th {
    background-color: rgb(83, 72, 206, 0.2) !important;
  }
  .principle-table .principle-header-economicParticipation th {
    background-color: rgb(183, 225, 247, 0.2) !important;
  }
  .principle-table .principle-header-commitmentToCommunity th {
    background-color: rgb(155, 204, 120, 0.2) !important;
  }
  .principle-table .principle-header-autonomyIndependence th {
    background-color: rgb(229, 87, 99, 0.2) !important;
  }
  .principle-table .principle-header-educationTraining th {
    background-color: rgb(242, 170, 118, 0.2) !important;
  }
  .principle-table .principle-header-cooperationBetweenCoops th {
    background-color: rgb(255, 255, 168, 0.2) !important;
  }

  table.principles-total thead th {
    border-color: #e3e6f0;
  }

  .balance-container blockquote, .balance-container pre {
    border: none;
    border-left: 2px solid #b7b9cc;
    padding-left: 1em;
    page-break-inside: avoid;
  }

  .balance-container * {
    color: black !important;
    page-break-inside: auto !important;
  }

  .balance-container .balance-subtitle {
    display: block !important;
    max-width: none !important;
  }

  /*
  .balance-container table {
    page-break-inside: auto;
  }
  .balance-container table tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  */
}

@media (max-width: 320px) {
  /* date picker has a fixed width of 300px
  for extra small screen size should be same as input width*/
  .date-picker-width {
    width: unset !important;
  }
}
